require("dotenv").config();

const gtmMarketConfig = {
  us: "GTM-59RLTL2",
  uk: "GTM-5LZDVM4",
  ie: "GTM-W2PF8KD",
};

const marketBaseUrl = {
  us: "https://www.efexchangeyear.org",
  uk: "https://www.efexchangeyear.co.uk",
  ie: "https://www.efexchangeyear.ie",
};

const NON_INDEXABLE_SITES = [
  "/iec-rewards",
  "/news",
  "/landing-page",
  "/rewards",
];

module.exports = {
  siteMetadata: {
    title: "Host a Foreign Exchange Student | Become a Host Family with EF",
    description:
      "Open your home to an exchange student and open your world. Host a high school exchange student with the leader in high school exchange.",
    author: "EF HSEY",
    siteUrl:
      marketBaseUrl[process.env.MARKET] || "https://www.efexchangeyear.org",
    keywords: "", // TODO
  },
  plugins: [
    "gatsby-plugin-react-helmet",
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: process.env.GATSBY_STORYBLOK_ACCESS_TOKEN,
        homeSlug: "us/home",
        version:
          process.env.GATSBY_CONTENT_VERSION === "production"
            ? "published"
            : "draft",
      },
    },
    `gatsby-plugin-force-trailing-slashes`,
    {
      resolve: "gatsby-plugin-robots-txt",
      options: {
        policy: [
          {
            userAgent: "*",
            allow: ["/"],
            disallow: NON_INDEXABLE_SITES,
          },
        ],
      },
    },
    {
      resolve: `gatsby-plugin-canonical-urls`,
      options: {
        siteUrl: marketBaseUrl[process.env.MARKET],
        stripQueryString: true,
      },
    },
    "gatsby-plugin-layout",
    "gatsby-transformer-sharp",
    "gatsby-plugin-sharp",
    "gatsby-plugin-sass",
    "gatsby-plugin-typescript",
    {
      resolve: "gatsby-plugin-tslint",
      options: {
        allExtensions: true, // defaults to false
        exclude: /(node_modules|cache|public)/,
      },
    },
    "gatsby-plugin-emotion",
    {
      resolve: "gatsby-plugin-google-tagmanager",
      options: {
        id: gtmMarketConfig[process.env.MARKET],
        includeInDevelopment: true,
        // datalayer to be set before GTM is loaded
        defaultDataLayer: { platform: "gatsby" },
      },
    },
    {
      resolve: "gatsby-plugin-manifest",
      options: {
        icon: "src/favicon.png",
      },
    },
    {
      resolve: "gatsby-plugin-webpack-bundle-analyzer", // go to http://localhost:8888 to see the bundle analyzer
      options: {
        openAnalyzer: false,
      },
    },
    `@wardpeet/gatsby-plugin-static-site`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `assets`,
        path: `${__dirname}/assets`,
      },
    },
    {
      resolve: "gatsby-plugin-copy-files",
      options: {
        source: `${__dirname}/assets/${process.env.MARKET}`,
        destination: ``,
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        //exclude: NON_INDEXABLE_SITES,
        // Default config
        serialize: ({ site, allSitePage }) =>
          allSitePage.edges
            .map((edge) => {
              // Added extra code to filter some urls
              let url = site.siteMetadata.siteUrl + edge.node.path;

              // filter -test or test- folders/files from the resulting sitemap
              if (url.match(/[-]*(test)[-]*/i)) return null;

              // Check if the url should not be indexable
              const matches = NON_INDEXABLE_SITES.filter((e) =>
                url.includes(e)
              );
              if (matches.length > 0) return null;

              const priority = url === site.siteMetadata.siteUrl ? 1.0 : 0.8;

              return {
                url,
                changefreq: `daily`,
                lastmod: new Date().toISOString().split("T")[0],
                priority,
              };
            })
            .filter((e) => e),
      },
    },
    `gatsby-plugin-svgr-svgo`,
    {
      resolve: `@ef-global/gatsby-source-hsey-students`,
      options: {
        env: "prod",
      },
    },
  ],
};
